.no-results {
  margin-top: 120px;

  > * {
    width: 200px;
    text-align: center;
  }

  &.mobile {
    margin-top: 40px;
  }

  .icon {
    color: #e2e3e9;
    font-size: 78px;
  }

  h2 {
    margin-top: 20px;
    margin-bottom: 0;
    color: #354052;
    font-size: 18px;
  }

  p {
    margin: 0;
    color: #7f8fa4;
    font-weight: 600;
  }

  .actions {
    margin-top: 25px;

    span {
      padding-bottom: 2px;
      margin-right: 4px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
