@import '../style/colors.scss';

.alert {
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  padding: 9px;
  margin-bottom: 0.5em;
  transition: all 0.5s;

  animation-duration: 0.5s;
  animation-name: bounceIn;

  &:before {
    content: '\f05a';
    font-family: FontAwesome;
    color: #ffffff;
    opacity: 0.7;
    padding-left: 2px;
    padding-right: 12px;
    transform: scale(1.25);
  }
  &.alert-warn {
    background-color: $orange;
  }
  &.alert-success {
    background-color: $green;
  }
  &.alert-danger {
    background-color: $red;
  }
  &.alert-info {
    background-color: #7f8fa4;
  }

  &.alert-danger:before {
    content: '\f057';
  }

  &.fade {
    opacity: 0;
  }
  &.slide {
    font-size: 0;
    padding: 0;
    opacity: 0;
  }

  .close {
    transform: rotate(-315deg) translate(-6px, -5px);
    opacity: 0.4;
    float: right;
    font-size: 27px;
    font-weight: 500;
    cursor: pointer;
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  60% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 900px) {
  notification {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
  }
  .alert {
    border-radius: 0;
    padding: 13px;
  }
}
