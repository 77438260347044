my-date-picker {
  .mydp {
    .inlinedp {
      margin: 0 auto;
    }
    .selbtngroup {
      height: 36px;

      button {
        margin-top: 2px;
        margin-bottom: -2px;
      }
    }
  }
  &.range-selected {
    .mydp .selectiongroup .selbtngroup { width: 1px; }
  }
  &.mobile-date-select {
    .mydp {
      height: 100%;
      .selectiongroup {
        height: 100%;
        background: rgb(245, 245, 245);
        border: none;
      }
      .selection {
        background: none;
        height: 100% !important;
        padding: 0;
        color: rgb(142, 142, 147);
        font-weight: 400;
        font-size: 17px !important;
      }
    }
  }
}

@supports (-ms-ime-align: auto) {
  my-date-picker .mydp .selbtngroup .mydpicon {
    top: 20px;
  }
}
