/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  // opacity: 0.8;
  // -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  // filter: alpha(opacity=80);
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;

  color: #a3acaf;
  border-radius: 4px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
  background-color: #ffffff;

  // Hide icons
  .mat-sort-header-arrow {
    display: none;
  }
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.draggable {
  * {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
}
