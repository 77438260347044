.gm-style-iw {
  padding-top: 16px;
}

.gm-style-iw + div {
  right: 12px !important;
  top: 6px !important;

  background: url(/assets/img/icons/icn-close.svg) no-repeat;
  background-position: -5px -5px;

  img {
    display: none;
  }
}

.gm-style > div:first-child > div:last-child > div:last-child > div:last-child {
  width: 140px !important;

  & > div:first-child > div:nth-child(2) {
    width: 140px !important;
  }

  & > div:first-child > div:last-child {
    width: 138px !important;
  }
}

.locations {
  .gm-style > div:first-child > div:last-child > div:last-child > div:last-child {
    width: 200px !important;

    & > div:first-child > div:nth-child(2) {
      width: 200px !important;
    }

    & > div:first-child > div:last-child {
      width: 198px !important;
    }
  }
}

.pac-container {
  z-index: 99999;
}
