@import './colors.scss';

mat-form-field {
  width: 100%;
  max-width: 500px;
  mat-chip-list {
    .mat-chip-list-wrapper {
      padding: 0 3px 3px;
      height: auto;
      border-radius: 4px;
      background-color: #ffffff;
      border: solid 1px #dfe3e9;
      input {
        margin: 3px 0 0;
        padding: 5px 7px 5px 12px;
        height: 28px;
        border-radius: 3px;
        color: #333c48;
        font-size: 14px;
        font-weight: 600;
        flex: 0 0 0;
        &.mat-chip-input {
          width: auto;
        }
        &:focus {
          border: none;
          width: auto;
        }
        &::after {
          content: '&plus;';
        }
      }
    }
    mat-chip,
    .mat-chip:not(.mat-basic-chip),
    .mat-chip:not(.mat-basic-chip) + .mat-chip:not(.mat-basic-chip) {
      height: 28px;
      border-radius: 3px;
      background-color: #f1f4f8;
      border: solid 1px #d5dce6;
      color: #333c48;
      font-size: 14px;
      font-weight: 600;
      padding: 5px 7px 5px 12px;
      margin: 3px 3px 0 0;
    }

    mat-autocomplete {
      mat-option {
      }
    }
  }

  .mat-input-wrapper {
    padding-bottom: 0;
  }
}

.mat-form-field-infix {
  padding: 0;
  border-top: none;
}

.mat-form-field-underline,
.mat-input-underline {
  display: none;
  visibility: none;
}

.mat-autocomplete-panel {
  transition: height 0.3s ease-in, box-shadow 0.3s;
  box-shadow: none;
  border-radius: 4px;
  overflow: hidden;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track {
    border-radius: 0 0 4px 0;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border: 2px solid #fff;
    background-color: #aaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: #eee;
    all: unset;
  }

  &.mat-autocomplete-visible {
    box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.06);
  }
  mat-option {
    box-shadow: inset 0 -1px 0 0 #dfe3e9;
    font-size: 14px;
    font-weight: 600;
    color: #354052;
    padding: 0px 40px 0px 14px;
    white-space: nowrap;
    min-height: 37px;
    line-height: 37px;
    height: auto;
    &:hover:not(.mat-option-disabled) {
      background: none;
      color: $blue;
    }
  }
}
