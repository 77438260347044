@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ug1mdt');
  src:  url('fonts/icomoon.eot?ug1mdt#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ug1mdt') format('truetype'),
    url('fonts/icomoon.woff?ug1mdt') format('woff'),
    url('fonts/icomoon.svg?ug1mdt#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-previous-arrow:before {
  content: "\e91e";
}
.icon-next-arrow:before {
  content: "\e91f";
}
.icon-dispatch:before {
  content: "\e91d";
}
.icon-sort:before {
  content: "\1f31e";
}
.icon-accept:before {
  content: "\e900";
}
.icon-checkmark:before {
  content: "\e901";
}
.icon-connections:before {
  content: "\e902";
}
.icon-delete:before {
  content: "\e903";
}
.icon-down-arrow:before {
  content: "\e904";
}
.icon-up-arrow:before {
  content: "\e920";
}
.icon-error:before {
  content: "\e905";
}
.icon-fleet:before {
  content: "\e906";
}
.icon-info:before {
  content: "\e907";
}
.icon-invoices:before {
  content: "\e908";
}
.icon-more:before {
  content: "\e909";
}
.icon-projects:before {
  content: "\e90a";
}
.icon-reject:before {
  content: "\e90b";
}
.icon-resend:before {
  content: "\e90c";
}
.icon-close:before {
  content: "\e90d";
}
.icon-search:before {
  content: "\e90e";
}
.icon-settings:before {
  content: "\e90f";
}
.icon-success:before {
  content: "\e910";
}
.icon-team:before {
  content: "\e911";
}
.icon-collapse:before {
  content: "\e912";
}
.icon-expand:before {
  content: "\e913";
}
.icon-plus:before {
  content: "\e914";
}
.icon-warning .path1:before {
  content: "\e915";
  color: rgb(0, 0, 0);
}
.icon-warning .path2:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-calendar:before {
  content: "\e917";
}
.icon-apple:before {
  content: "\e918";
}
.icon-android:before {
  content: "\e919";
}
.icon-upload:before {
  content: "\e91a";
}
.icon-rows:before {
  content: "\e91b";
}
.icon-grid:before {
  content: "\e91c";
  color: #d0d2dc;
}
.icon-material:before {
  content: "\e921";
}
.icon-distance:before {
  content: "\e922";
  color: #696969;
}
.icon-none:before {
  content: "\e923";
}
.icon-ticket:before {
  content: "\e924";
}
.icon-clock:before {
  content: "\e925";
}
.icon-weight:before {
  content: "\e926";
}
.icon-truck:before {
  content: "\e927";
}
.icon-filter:before {
  content: "\e928";
}
.icon-time:before {
  content: "\e929";
}
.icon-pencil:before {
  content: "\e92a";
}
.icon-digital-scale:before {
  content: "\e92b";
}
.icon-dashboard:before {
  content: "\e92c";
}
.icon-locations:before {
  content: "\e92d";
}
.icon-help:before {
  content: "\e92e";
}
.icon-plus-circle:before {
  content: "\e92f";
}
.icon-orders:before {
  content: "\e93b";
}
.icon-inventory:before {
  content: "\e93a";
}
.icon-no-results:before {
  content: "\e932";
}
.icon-back:before {
  content: "\e933";
}
.icon-export:before {
  content: "\e934";
}
.icon-accepted:before {
  content: "\e935";
}
.icon-declined:before {
  content: "\e936";
}
.icon-notread:before {
  content: "\e937";
}
.icon-read:before {
  content: "\e938";
}
.icon-waiting:before {
  content: "\e939";
}
.icon-orders-old:before {
  content: "\e930";
}
.icon-old-inventory:before {
  content: "\e931";
}
.icon-bell:before {
  content: "\e951";
}
