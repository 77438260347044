@import '../assets/fonts/icomoon/style.css';
@import '../../node_modules/timepicker/jquery.timepicker.css';

$text-gray: #354052;
$text-light-gray: #7f8fa4;

// bg colors
$red: #ed1c24;
$dark_gray: #516173;
$orange: #f7981c;
$light_gray: #dfe1e5;
$cancelled_gray: #c7d2e0;
$med_gray: #c2cad4;
$content-background: #f1f4f6;
$edit_blue: #0078d9;
$void_red: #ed1c24;
$retake_orange: #f7981c;
$finalized_green: #00c853;

@mixin icomoon() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import 'buttons';
@import 'typography';
@import 'forms';
@import 'modals';
@import 'lists';
@import 'labels';
@import 'notifications';
@import 'tags';
@import 'dropdown';
@import 'mobile';
@import 'results';
@import 'action-bar';
@import 'mobile';
@import 'sidebar';
@import 'maps';
@import 'date-picker';
@import 'material-overrides';
@import 'dragula';
@import 'theme';

// styles in src/style directory are applied to the whole page
body {
  background: #fff;
  font-size: 14px;
}

html,
body {
  height: 100%;
  margin: 0;
}

.app {
  min-height: 390px;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
}

.app-content {
  background-color: $content-background;
  height: 100%;
  width: 100%;
}

* * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}
h1 {
  font-size: 20px;
  font-weight: 400;
  color: $text-gray;
}
h2 {
  font-size: 18px;
  color: $text-gray;
  font-weight: 400;
}
h3 {
  font-size: 16px;
  color: $text-gray;
}
p {
  font-size: 14px;
  color: $text-gray;
  font-weight: 100;
}
.smaller {
  font-weight: 500;
  color: #354052;
}
.additional {
  font-weight: 400;
  color: $text-light-gray;
}
.description {
  color: $text-light-gray;
  font-size: 12px;
}

.missing-required:before {
  content: '--';
  font-size: 14px;
  font-weight: 600;
  color: #ed1c24;
}

// tabs
.mat-tab-label {
  &:focus {
    background: transparent;
  }
  .mat-ripple-background,
  .mat-ripple-foreground {
    display: none;
  }
}

.organization-actions .dropdown .dropdown-menu {
  right: 0;
  width: 100px;
}

.menu-toggle-list.expanded a {
  background: #154b80;
}

// Safari custom elements workaround
[my-login],
[ruckit-connection],
[ruckit-team],
[ruckit-users-list],
[ruckit-trips] {
  display: block;
  height: 100vh;
}

.full-height {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  // height: 100%;
  // height: 100VH;
}

// make elements full height in safari
new-job {
  height: 100vh;
  overflow-y: scroll;
}
ruckit-dispatch action-bar mat-toolbar {
  margin-bottom: 0;
}
mat-toolbar {
  min-height: unset !important;
}

.main-component-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  overflow: hidden;
  & > :last-child {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 100vh;
  }
}

.upload-image-dropzone {
  width: 370px;
  height: 194px;
  border-radius: 4px;
  margin-bottom: 30px;
  &.round {
    border-radius: 50%;
    width: 250px;
    height: 250px;
    max-height: 250px;

    label {
      border-radius: 50%;
    }

    &.filled {
      height: 250px;
      label {
        display: none;
        height: 80px;
        z-index: 1;
        padding-top: 20px;
      }
      &:hover {
        label {
          display: block;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
  background-color: #ffffff;
  border: solid 1px rgba(255, 255, 255, 0.25);
  padding: 3px;
  position: relative;
  color: #7f8fa4;
  text-align: center;
  &.filled {
    padding: 0;
    min-height: 100%;
    display: flex;
    align-items: center;
    height: auto;
    overflow: hidden;
    &:hover {
      label {
        display: block;
      }
    }
    label {
      position: absolute;
      display: none;
      bottom: 0;
      height: 40px;
      background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
      border: none;
      color: #fff;
      margin: 0;
      .icon-upload {
        color: #fff;
        display: inline-block;
        font-size: 25px;
        vertical-align: middle;
        margin-right: 4px;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
  label {
    height: 100%;
    width: 100%;
    border: dashed 3px #ced0da;
    content: '';
    display: block;
    padding: 40px;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    color: #7f8fa4;
    .icon-upload {
      color: #e2e3e9;
      font-size: 47px;
      display: block;
      text-align: center;
    }
  }
  input {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    position: absolute;
  }
}

.sidebar-container {
  overflow: hidden;
  transition: width 0.5s;
  width: 56px;
  flex: 0 0 auto;
  .nav-logo {
    object-fit: cover;
    width: 13px;
    object-position: 0;
    transition: width 0.5s;
  }
  .menu-toggle-list .menu-section {
    height: 0;
    transition: height 0.4s;
    overflow: hidden;
  }
  .menu-heading {
    transition: opacity 0.2s;
    opacity: 0;
  }
  a {
    white-space: nowrap;
  }
  &:hover {
    width: 200px;
    .nav-logo {
      width: 78px;
    }
    .menu-heading {
      opacity: 1;
    }
    .menu-toggle-list .menu-section {
      height: 50px;
    }
  }
}

.btn-filters {
  margin-left: 20px;
  i {
    color: #c7d2e0;
  }
}

.cdk-focus-trap-content {
  height: 100%;
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-270 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

@media print {
  body {
    height: auto;
  }
  body * {
    visibility: hidden;
    overflow-y: visible;
    pointer-events: auto;
  }
  .cdk-overlay-container {
    position: absolute;
  }
}
