.sidebar {
  background-color: #012649;

  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  justify-content: space-between;
  height: 100%;

  .sidebar-logo-container {
    width: 100%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(254, 254, 254, 0.15);

    img {
      height: 25px;
    }
  }

  .pinned-bottom {
    width: 100%;
    margin-bottom: 44px;
  }

  [class^="icon-"] {
    margin-right: 15px;
    font-size: 20px;
  }

  h2.menu-heading {
    padding-left: 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.17;
    color: #7f8fa4;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
  }

  div.menu-heading {
    margin-top: 40px;

    &.first {
      margin-top: 0px;

      h2.menu-heading {
        margin-top: 24px;
      }
    }
  }
}
.sidebar-container {
  background-color: #012649;
  overflow: hidden;
  transition: width 1s;
  width: 56px;
  flex: 0 0 auto;
  .nav-logo {
    object-fit: cover;
    width: 13px;
    object-position: 0;
    transition: width 0.5s;
  }
  .menu-toggle-list .menu-section {
    height: 0;
    transition: height 0.4s;
    overflow: hidden;
  }
  .menu-heading {
    transition: opacity 0.2s;
    opacity: 0;
  }
  a {
    white-space: nowrap;
  }

  &:hover {
    width: 200px;
    @media (min-width: 150px) {
      .application-version {
        margin-bottom: -32px;
        display: block;
      }
    }
    .nav-logo {
      width: 78px;
    }
    .menu-heading {
      opacity: 1;
    }
    .menu-toggle-list .menu-section {
      height: 50px;
    }
  }
}
