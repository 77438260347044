
.app .sidebar-container {
  mobile-sidebar {
    display: none;
  }
}

.mobile-visible {
  display: none;
}

@media (max-width: 900px) {

  .desktop-visible {
    display: none;
  }

  .mobile-visible {
    display: block;
  }

  div.app {
    flex-direction: column;
    height: 100vh;
    .sidebar-container {
      z-index: 9999;
      my-sidebar {
        display: none;
      }

      mobile-sidebar {
        display: block;
        left: calc(-100vw + 88px);
        position: absolute;
        transition: transform 0.3s;
      }
    }

    .main-component-container {
      transition: transform 0.3s;
    }

    &.sidebar-open {
      overflow-x: hidden;

      .sidebar-container {
        mobile-sidebar {
          transform: translateX(calc(100vw - 88px));
        }
      }

      .main-component-container {
        transform: translateX(calc(100vw - 88px));
      }
    }
  }
  .main-component-container {
    flex: 1 1 auto;
    & > :last-child {
      height: 100%;
    }
  }
  .edit-drawer, .side-drawer {
    height: 100%;
    top: 0;
    right: 0;
    max-width: 100%;
  }
  
  action-bar mat-toolbar-row .action-bar-content {
    padding-left: 16px;
    padding-right: 16px;
  }
  .dropdown.user.user .dropdown-button {
    padding-right: 8px;
  }

  .action-bar-content {
    margin-top: -15px;
    padding: 0 15px;
    .subnav {
      margin-top: 20px;
      .links {
        justify-content: space-between;
        a {
          margin-right: 0;
          padding-bottom: 10px;
          &.active { padding-bottom: 10px; }
        }
      }
    }
    .left-actions {
      margin-top: 0;
    }
    .right-actions {
      margin-top: 15px;

      :first-child {
        margin-left: 0;
      }
    }
  }

  .grid {
    .list-table {
      .row {
        width: calc(100VW - 30px) !important;
      }
    }
  }

  mat-toolbar-row {
    height: 44px;
  }

  .cdk-overlay-container {
    z-index: 10000;
  }
}

@media (max-width: 600px) and (orientation: portrait) {
  mat-toolbar-row {
    height: 44px !important;
  }
}
